// FeedHeader.js
import React from 'react';
import { Box, Button, ToggleButtonGroup, ToggleButton, Tooltip, SelectChangeEvent } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FilterMenu from './FilterMenu';
import { FilterPosition } from './Feed';
interface FeedHeaderProps {
    isMobile: boolean;
    alignment: string | undefined;
    handleAlignment: (event: React.MouseEvent<HTMLElement>, newAlignment: string) => void;
    setIsAddModalOpen: (open: boolean) => void;
    toggleFilterMenu: () => void;
    sortCriteria: string;
    filterPlatform: string;
    handleSortChange:(event: SelectChangeEvent) => void; 
    handleFilterPlatformChange: (event: SelectChangeEvent) => void;
    showFilterMenu: boolean;
    filterPosition: FilterPosition;
    handleFilterPositionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }
const FeedHeader = ({
  isMobile,
  alignment,
  handleAlignment,
  setIsAddModalOpen,
  toggleFilterMenu,
  sortCriteria,
  filterPlatform,
  handleSortChange,
  handleFilterPlatformChange,
  showFilterMenu,
  filterPosition,
  handleFilterPositionChange,
} :FeedHeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px', height: '36.5px' }}>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleAlignment}
        sx={{ padding: '5px' }}
        aria-label="text alignment"
      >
        <Tooltip title="Watch the posts you sent to social media">
          <ToggleButton selected={alignment === 'sent'} value="sent" aria-label="left aligned">
            <SendIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Watch the posts you bookmarked">
          <ToggleButton selected={alignment === 'bookmark'} value="bookmark" aria-label="centered">
            <BookmarkIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title="Add a post to the feed. All the users could see what you added">
          <Button
            size={isMobile ? "small" : "large"}
            endIcon={<AddIcon />}
            sx={{ mr: 1 }} // Adjust as needed
            variant="contained"
            onClick={() => setIsAddModalOpen(true)}
          >
            Submit post
          </Button>
        </Tooltip>
        
        <FilterMenu
          sortCriteria={sortCriteria}
          filterPlatform={filterPlatform}
          handleSortChange={handleSortChange}
          handleFilterPlatformChange={handleFilterPlatformChange}
          showFilterMenu={showFilterMenu}
          toggleFilterMenu={toggleFilterMenu}
          handleFilterPositionChange={handleFilterPositionChange}
          filterPosition={filterPosition}
        />
      </Box>
    </Box>
  );
};

export default FeedHeader;
