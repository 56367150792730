import { Box, Button, Typography, CircularProgress, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { Post, dataSource } from '../../utils/interfaces';
import { LOGSTYPE, POSTS_DB, REPORT_OPTIONS, USERS_REPORTS_CONTAINER } from '../../utils/constant';
import { useCallback, useEffect, useRef, useState } from 'react';
import { fixInstagramIframe } from '../../utils/postUtils';
import { addLogsToDatabase, addPostToDatabase } from '../../utils/webServices';
import { CardActionStyles } from './FeedStyle';
import { useAuth0 } from '@auth0/auth0-react';
import IntroductionSection from './IntroductionSection';
import AddPostModal from './AddPostModal';
import AddReportModal, { ReportData } from './AddReportModal';
import useFetchPosts from './Hooks/useFetchPosts';
import FeedHeader from './FeedHeader';
import LoadingIndicator from './LoadingIndicator';
import PostGrid from './PostGrid';

export interface FilterPosition {
    anti: boolean,
    pro: boolean
}
export interface FeedComponentProps  {
    dataSource: dataSource
}

const Feed = ({dataSource}: FeedComponentProps) => {
    const styles = CardActionStyles;

    const { logout, user } = useAuth0();
    const [height, setHeight] = useState(window.innerHeight - 50);
    const [width, setWidth] = useState(window.innerWidth);
    const [pageSize, setPageSize] = useState(10);
    const [isMobile, setIsMobile] = useState(false);

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [newPostUrl, setNewPostUrl] = useState('');
    const [isFinishedFetch, setIsFinishedFetch] = useState(false);
    const [page, setPage] = useState(1);
    const [alignment, setAlignment] = useState<string>();
    
    const [addedPosts, setAddedPosts] = useState<Post[]>([]);
    const [combinedPosts, setCombinedPosts] = useState<Post[]>([]);

    let loadingNewPosts = false
    
    const [sortCriteria, setSortCriteria] = useState(' ');
    const [filterPlatform, setFilterPlatform] = useState('all');
    const [filterPosition, setFilterPosition] = useState({
        pro: true,
        anti: true
    });

    const [clickedAction, setClickedAction] = useState(false);
    const [activePostId, setActivePostId] = useState(-1);
    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const { posts, isLoading, hasMore } = useFetchPosts(dataSource, pageSize, page, sortCriteria, filterPlatform, filterPosition, user?.email, alignment);

    
    const lastPostObserver = useRef<IntersectionObserver>();
    const lastPostElementRef = useCallback((node: HTMLDivElement) => {
        if (isLoading) return;
        if (lastPostObserver.current) lastPostObserver.current.disconnect();
    
        lastPostObserver.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && !loadingNewPosts) {
                console.log('Reached bottom, current page is', page, "and hasMore is", hasMore);
                setPage(prevPage => prevPage + 1);
                loadingNewPosts = true; // Prevent multiple triggers
                setTimeout(() => {
                    loadingNewPosts = false;
                }, 500); // Reset after a delay to allow for state update
            }
        });
    
        if (node) lastPostObserver.current.observe(node);
    }, [isLoading, hasMore, page]);
    
    useEffect(() => {
        if (!isLoading) {
            setIsFinishedFetch(true)
        }
    }, [isLoading, posts]);


    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        console.log(newAlignment);
        setAlignment(newAlignment);
        setPage(1);
    };


    useEffect(() => {
        console.log('rendering asked. page number ', page, ' posts redenred ', posts)
        setCombinedPosts([...addedPosts, ...posts]);
    }, [posts, addedPosts]);


    const handleSortChange = (event: SelectChangeEvent<string>) => {
        setSortCriteria(event.target.value);
    };
    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };
    const handleFilterPlatformChange = (event: SelectChangeEvent<string>) => {
        setFilterPlatform(event.target.value);
    };
    const handleFilterPositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        const checkedFilters = Object.values(filterPosition).filter(value => value).length;

        if (!checked && checkedFilters === 1) {
            return;
        }

        setFilterPosition(prev => ({
            ...prev,
            [name]: checked
        }));
    };
    const handleReport = async (reportData: ReportData) => {
        console.log(reportData);
        addPostToDatabase({
            id: Date.now().toString(),
            reportText: reportData,
            user: user ?? 'not found'
        }, USERS_REPORTS_CONTAINER, POSTS_DB)
        console.log('sent!')
        setIsReportModalOpen(false);
    };
    const handleActionClickToggle = (open: boolean, postId: number) => {
        console.log(postId)
        setClickedAction(open);
        setActivePostId(postId);
    };

    fixInstagramIframe();
    const updateDimenstions = () => {
        setHeight(window.innerHeight - 50);
        setWidth(window.innerWidth);
        if (width < 765) {
            setIsMobile(true)
            setPageSize(1)
        }
        if (width > 765 && width < 1280) { setPageSize(2) }
        if (isMobile && (width > 765)) setIsMobile(false)
        if (width > 1280) { setPageSize(3) }
    };

    useEffect(() => {
        updateDimenstions();
        window.addEventListener('resize', updateDimenstions);
        return () => window.removeEventListener('resize', updateDimenstions);
    }, []);

    return (<>
        <IntroductionSection logout={logout} isMobile={isMobile} setIsReportModalOpen={setIsReportModalOpen} />
        <Box sx={{ borderRadius: '10px', background: "rgb(243, 247, 247)" }}>
            <FeedHeader
                isMobile={isMobile}
                alignment={alignment}
                handleAlignment={handleAlignment}
                setIsAddModalOpen={setIsAddModalOpen}
                toggleFilterMenu={toggleFilterMenu}
                sortCriteria={sortCriteria}
                filterPlatform={filterPlatform}
                handleSortChange={handleSortChange}
                handleFilterPlatformChange={handleFilterPlatformChange}
                showFilterMenu={showFilterMenu}
                handleFilterPositionChange={handleFilterPositionChange}
                filterPosition={filterPosition}
            />
            <AddPostModal
                isAddModalOpen={isAddModalOpen}
                newPostUrl={newPostUrl}
                setNewPostUrl={setNewPostUrl}
                addedPosts={addedPosts}
                setIsAddModelOpen={setIsAddModalOpen}
                setAddedPosts={setAddedPosts}
                user={user}
                closeAddModal={() => setIsAddModalOpen(false)}
            />
            <AddReportModal
                isReportModalOpen={isReportModalOpen}
                REPORT_OPTIONS={REPORT_OPTIONS}
                handleReport={handleReport}
                closeReportModal={() => setIsReportModalOpen(false)}
            />

            {isFinishedFetch ? (
                <PostGrid
                    combinedPosts={combinedPosts}
                    isMobile={isMobile}
                    isLoading={isLoading}
                    hasMore={hasMore}
                    lastPostElementRef={lastPostElementRef}
                    handleActionClickToggle={handleActionClickToggle}
                    clickedAction={clickedAction}
                    activePostId={activePostId}
                />
            ) : (<LoadingIndicator />)
            }
            {!hasMore && <Box sx={{ fontSize: '30px', padding: '50px', alignSelf: 'center', display: 'flex', justifyContent: 'center' }}>No more posts to show</Box>}
        </Box >
    </>
    );

}

export default Feed;

